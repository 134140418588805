import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  BaseGetByIdRequest,
  BaseGetCollectionByIdRequest,
} from '@app-types/base/base';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { MailAccountContract } from '@app-types/api/mail-account';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { EmailGroupClient } from '@app-services/api/clients/email-group.client';
import { GroupClient } from '@app-services/api/clients/group.client';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-email-add-groups-dialog',
  templateUrl: './email-add-groups-dialog.component.html',
  styleUrls: ['./email-add-groups-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    SomethingWentWrongComponent,
    LoadingButtonComponent,
    MatCheckboxModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
  ],
})
export class EmailAddGroupsDialogComponent implements OnInit {
  public thisGroups: any[] = [];
  public existedGroups: any[] = [];
  public isLoading: boolean = true;
  public existedGroupsResponse: any;
  public isSave: boolean = false;
  public tags: string[] = [];
  private mailAccount: MailAccountContract;
  public thisGroupsIds: number[] = [];
  public resultGroupsIds: number[] = [];
  public isError: boolean = false;
  public isSavingGroups: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EmailAddGroupsDialogComponent>,
    public emailGroupClient: EmailGroupClient,
    public groupClient: GroupClient,
    public matchError: MatchError,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  async ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.ok();
      }
    });
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    try {
      const response = await this.mailAccountClient.getById(
        new BaseGetByIdRequest(this.data.mailAccountId)
      );
      this.mailAccount = response.mailAccount;
      this.existedGroupsResponse =
        await this.groupClient.getGroupsForOrganization(
          new BaseGetCollectionByIdRequest(
            this.mailAccount.organizationId,
            1,
            25
          )
        );
      this.existedGroups = this.existedGroupsResponse.data;
      if (this.data.ids.length === 1) {
        this.thisGroups = await this.emailGroupClient.getGroupsForEmails({
          list: this.data.ids,
        });
        this.thisGroupsIds = this.thisGroups
          .map(e => e.groupId)
          .filter((e, i, arr) => arr.indexOf(e) === i);
      } else {
        const allExistedGroupsIds = this.existedGroups.map(e => e.groupId);
        await this.emailGroupClient.removeGroupsFromEmails({
          sourceIds: this.data.ids,
          toIds: allExistedGroupsIds,
        });
        this.isSave = true;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  public isChecked(groupId: number): boolean {
    return this.thisGroupsIds.includes(groupId);
  }

  async addGroupToEmails(isCheck: boolean, groupId: number): Promise<void> {
    if (isCheck) {
      this.thisGroupsIds.push(groupId);
    } else {
      this.thisGroupsIds = this.thisGroupsIds.filter(e => e !== groupId);
    }
  }

  async ok(): Promise<any> {
    if (this.existedGroups.length === 0) {
      this.dialogRef.close();
      return;
    }
    this.isSavingGroups = true;
    try {
      if (this.thisGroupsIds.length) {
        await this.emailGroupClient.addGroupsToEmails({
          sourceIds: this.data.ids,
          toIds: this.thisGroupsIds,
        });
      } else {
        const allGroupIds = this.existedGroups?.map(e => e?.groupId);
        await this.emailGroupClient.removeGroupsFromEmails({
          sourceIds: this.data.ids,
          toIds: allGroupIds,
        });
      }
      this.isSave = true;
      this.resultGroupsIds = this.thisGroupsIds;
      if (this.isSave) {
        const res = this.existedGroups.filter(e =>
          this.resultGroupsIds.includes(e.groupId)
        );
        this.dialogRef.close(res);
      } else {
        this.dialogRef.close();
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSavingGroups = false;
    }
  }
}
